<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M21,2.2h-7c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h5.2l-6.8,6.8c-0.3,0.3-0.3,0.8,0,1.1s0.8,0.3,1.1,0 l6.8-6.8V10c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V3C21.8,2.6,21.4,2.2,21,2.2z"
  />
  <path
    d="M21,14c-0.4,0-0.8,0.3-0.8,0.8v4.8c0,0.4-0.3,0.8-0.8,0.8h-15c-0.4,0-0.8-0.3-0.8-0.8v-15 c0-0.4,0.3-0.8,0.8-0.8H9c0.4,0,0.8-0.3,0.8-0.8S9.4,2.2,9,2.2H4.5c-1.2,0-2.2,1-2.2,2.2v15c0,1.2,1,2.2,2.2,2.2h15 c1.2,0,2.2-1,2.2-2.2v-4.8C21.8,14.3,21.4,14,21,14z"
  />
</svg>
