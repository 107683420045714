//
// Copyright © 2020 Anticrm Platform Contributors.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
//

import chunter from '@hcengineering/chunter'
import { loadMetadata } from '@hcengineering/platform'

const icons = require('../assets/icons.svg') as string // eslint-disable-line
loadMetadata(chunter.icon, {
  Chunter: `${icons}#chunter`,
  Hashtag: `${icons}#hashtag`,
  Thread: `${icons}#thread`,
  Lock: `${icons}#lock`,
  ChannelBrowser: `${icons}#channelbrowser`,
  ChunterBrowser: `${icons}#chunterbrowser`,
  Copy: `${icons}#copy`,
  Messages: `${icons}#messages`,
  Bookmarks: `${icons}#bookmarks`
})
