<!--
// Copyright © 2020, 2021 Anticrm Platform Contributors.
// Copyright © 2021, 2024 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->
<script lang="ts">
  import { isEmptyMarkup, markupToJSON } from '@hcengineering/text'
  import Node from './markup/Node.svelte'

  export let message: string
  export let preview = false

  $: node = markupToJSON(message)
  $: empty = isEmptyMarkup(message)

  export function isEmpty (): boolean {
    return empty
  }
</script>

<div class="text-markup-view">
  <Node {node} {preview} />
</div>
