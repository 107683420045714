<script lang="ts">
  export let size: 'x-small' | 'small' | 'medium' | 'large' | 'full'
  export let fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M28 24.0044C28 26.2136 26.2091 28 24 28H8C5.79086 28 4 26.2091 4 24V8C4 5.79086 5.79086 4 8 4H10V3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3V4H19.9995V3C19.9995 2.44772 20.4472 2 20.9995 2C21.5518 2 21.9995 2.44772 21.9995 3V4H24C26.2091 4 28 5.79086 28 8C28 11.5569 28 19.2641 28 24.0044ZM19.9995 6V7C19.9995 7.55228 20.4472 8 20.9995 8C21.5518 8 21.9995 7.55228 21.9995 7V6H24C25.1046 6 26 6.89543 26 8V12H6V8C6 6.89543 6.89543 6 8 6H10V7C10 7.55228 10.4477 8 11 8C11.5523 8 12 7.55228 12 7V6H19.9995ZM6 14V24C6 25.1046 6.89543 26 8 26H24C25.1046 26 26 25.1046 26 24V14H6Z"
  />
</svg>
