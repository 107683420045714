<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  <path
    d="M10,2c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8C2,5.6,5.6,2,10,2z M10,3c-3.9,0-7,3.1-7,7c0,3.9,3.1,7,7,7c3.9,0,7-3.1,7-7C17,6.1,13.9,3,10,3z M7.2,12.4c1.5,1.5,3.9,1.6,5.5,0.2l0.2-0.2c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.4,0.1,0.6l-0.1,0.1l0,0c-2,2-5.1,1.9-7.1,0c-0.2-0.2-0.2-0.5,0-0.7C6.6,12.2,7,12.2,7.2,12.4z M12.5,7.5c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S11.9,7.5,12.5,7.5z M7.5,7.5c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S6.9,7.5,7.5,7.5z"
  />
</svg>
