<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.7241 10.0168L14.3535 13.6465C14.5488 13.8417 14.5488 14.1583 14.3535 14.3536C14.1602 14.5469 13.8478 14.5488 13.6522 14.3592L10.017 10.7238C8.9277 11.6312 7.5305 12.0836 6.11608 11.9871C4.70165 11.8905 3.37891 11.2524 2.423 10.2054C1.4671 9.15843 0.951637 7.78323 0.983845 6.36588C1.01605 4.94853 1.59345 3.59816 2.59593 2.59568C3.5984 1.59321 4.94877 1.01581 6.36612 0.983601C7.78347 0.951393 9.15867 1.46686 10.2057 2.42276C11.2526 3.37866 11.8908 4.70141 11.9873 6.11583C12.0839 7.53026 11.6314 8.92745 10.7241 10.0168ZM2.75851 3.99981C2.26404 4.73983 2.00012 5.60986 2.00012 6.49987C2.00144 7.69294 2.47597 8.83677 3.3196 9.68039C4.16323 10.524 5.30705 10.9986 6.50012 10.9999C7.39013 10.9999 8.26016 10.736 9.00018 10.2415C9.7402 9.74702 10.317 9.04422 10.6576 8.22195C10.9982 7.39968 11.0873 6.49488 10.9137 5.62197C10.74 4.74905 10.3114 3.94723 9.6821 3.31789C9.05276 2.68856 8.25094 2.25997 7.37802 2.08634C6.50511 1.91271 5.60031 2.00182 4.77804 2.34242C3.95578 2.68301 3.25297 3.25979 2.75851 3.99981Z"
  />
</svg>
