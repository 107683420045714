<script lang="ts">
  export let size: 'small' | 'medium' | 'large' | 'full'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 16 16">
  <path
    d="M7.75 0.75C7.75 0.335786 8.08579 0 8.5 0H11.25C11.6642 0 12 0.335786 12 0.75V3.5C12 3.91421 11.6642 4.25 11.25 4.25C10.8358 4.25 10.5 3.91421 10.5 3.5V2.56066L7.78033 5.28033C7.48744 5.57322 7.01256 5.57322 6.71967 5.28033C6.42678 4.98744 6.42678 4.51256 6.71967 4.21967L9.43934 1.5H8.5C8.08579 1.5 7.75 1.16421 7.75 0.75ZM5.28033 6.71967C5.57322 7.01256 5.57322 7.48744 5.28033 7.78033L2.56066 10.5H3.5C3.91421 10.5 4.25 10.8358 4.25 11.25C4.25 11.6642 3.91421 12 3.5 12H0.75C0.335786 12 0 11.6642 0 11.25V8.5C0 8.08579 0.335786 7.75 0.75 7.75C1.16421 7.75 1.5 8.08579 1.5 8.5V9.43934L4.21967 6.71967C4.51256 6.42678 4.98744 6.42678 5.28033 6.71967Z"
  />
</svg>
