<script lang="ts">
  export let fill: string = 'var(--primary-button-default)'
</script>

<svg class="svg-16px check" {fill} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.11101 2.17959C5.26216 1.41042 6.61553 0.999878 8 0.999878C9.85652 0.999878 11.637 1.73738 12.9497 3.05013C14.2625 4.36288 15 6.14336 15 7.99988C15 9.38435 14.5895 10.7377 13.8203 11.8889C13.0511 13.04 11.9579 13.9372 10.6788 14.467C9.3997 14.9968 7.99224 15.1355 6.63437 14.8654C5.2765 14.5953 4.02922 13.9286 3.05026 12.9496C2.07129 11.9707 1.4046 10.7234 1.13451 9.36551C0.86441 8.00764 1.00303 6.60018 1.53285 5.32109C2.06266 4.04201 2.95987 2.94876 4.11101 2.17959ZM11.3536 6.35355C11.5488 6.15829 11.5488 5.84171 11.3536 5.64645C11.1583 5.45118 10.8417 5.45118 10.6464 5.64645L7 9.29289L5.35355 7.64645C5.15829 7.45118 4.84171 7.45118 4.64645 7.64645C4.45118 7.84171 4.45118 8.15829 4.64645 8.35355L6.64645 10.3536C6.84171 10.5488 7.15829 10.5488 7.35355 10.3536L11.3536 6.35355Z"
  />
</svg>
