<!--
// Copyright © 2023 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->
<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import TimeInputBox from './TimeInputBox.svelte'

  export let currentDate: Date

  const dispatch = createEventDispatcher()

  function close (): void {
    currentDate.setSeconds(0, 0)
    dispatch('close', { value: currentDate })
  }

  export function canClose (): boolean {
    return true
  }
</script>

<div class="antiPopup popup">
  <TimeInputBox bind:currentDate on:close={close} />
</div>

<style lang="scss">
  .popup {
    border-radius: 0.5rem;
  }
</style>
