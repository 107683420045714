<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
</script>

<svg class="svg-{size}" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M2.6665 8L2.31295 7.64645L1.9594 8L2.31295 8.35355L2.6665 8ZM12.6665 8.5C12.9426 8.5 13.1665 8.27614 13.1665 8C13.1665 7.72386 12.9426 7.5 12.6665 7.5V8.5ZM6.31295 3.64645L2.31295 7.64645L3.02006 8.35355L7.02006 4.35355L6.31295 3.64645ZM2.31295 8.35355L6.31295 12.3536L7.02006 11.6464L3.02006 7.64645L2.31295 8.35355ZM2.6665 8.5H12.6665V7.5H2.6665V8.5Z"
    fill="#616161"
  />
</svg>
