<script lang="ts">
  export let size: 'small' | 'medium' | 'large' | 'full'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <path
    d="M18 6.00012V4.00012H28V14.0001H26V7.41012L15.7136 17.7008L15.7071 17.7073C15.3166 18.0978 14.6834 18.0978 14.2929 17.7073C13.9024 17.3167 13.9024 16.6836 14.2929 16.293L24.59 6.00012H18Z"
  />
  <path
    d="M7.8415 6.10109C8.88208 5.96118 9.94455 5.97558 11.0001 5.99059C11.5524 5.99845 12.0001 5.55245 12.0001 5.00017C12.0001 4.44788 11.5524 3.99873 11.0001 3.99235C9.85582 3.97912 8.7048 3.96702 7.57501 4.11892C6.64718 4.24366 5.82775 4.51563 5.17164 5.17174C4.51553 5.82785 4.24357 6.64727 4.11882 7.5751C4 8.45885 4.00003 11.5755 4.00007 12.9296V21.0707C4.00003 22.4248 4 23.5415 4.11882 24.4252C4.24357 25.3531 4.51553 26.1725 5.17164 26.8286C5.82775 27.4847 6.64718 27.7567 7.57501 27.8814C8.45875 28.0002 9.57537 28.0002 10.9295 28.0002H21.0706C22.4247 28.0002 23.5414 28.0002 24.4251 27.8814C25.353 27.7567 26.1724 27.4847 26.8285 26.8286C27.4846 26.1725 27.7566 25.3531 27.8813 24.4252C28.0332 23.2954 28.0211 22.1444 28.0079 21.0001C28.0015 20.4479 27.5524 20.0002 27.0001 20.0002C26.4478 20.0002 26.0018 20.4479 26.0096 21.0001C26.0247 22.0557 26.0391 23.1182 25.8992 24.1587C25.8042 24.8648 25.6369 25.1918 25.4143 25.4144C25.1917 25.637 24.8647 25.8043 24.1586 25.8993C23.4238 25.998 22.4426 26.0002 21.0001 26.0002H11.0001C9.55759 26.0002 8.57632 25.998 7.8415 25.8993C7.13545 25.8043 6.80843 25.637 6.58586 25.4144C6.36329 25.1918 6.19591 24.8648 6.10099 24.1587C6.00219 23.4239 6.00007 22.4426 6.00007 21.0002V13.0002C6.00007 11.5577 6.00219 8.57642 6.10099 7.8416C6.19591 7.13555 6.36329 6.80853 6.58586 6.58595C6.80843 6.36338 7.13545 6.19601 7.8415 6.10109Z"
  />
</svg>
