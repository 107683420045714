<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
</script>

<svg class="svg-{size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
  <path fill={'#4474F6'} d="M8,0L8,0c4.4,0,8,3.6,8,8l0,0c0,4.4-3.6,8-8,8l0,0c-4.4,0-8-3.6-8-8l0,0C0,3.6,3.6,0,8,0z" />
  <path
    fill={'#fff'}
    d="M7.3,10.6c-0.2,0-0.3-0.1-0.4-0.2l-1.8-2c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.8,0l1.4,1.5L10,5.6 c0.2-0.3,0.6-0.3,0.8-0.1c0.3,0.2,0.3,0.6,0.1,0.8l-3.2,4C7.7,10.5,7.5,10.6,7.3,10.6C7.3,10.6,7.3,10.6,7.3,10.6z"
  />
</svg>
