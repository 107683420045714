<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  export let opacity: string = '1'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g {opacity}>
    <path
      d="M12.0448 9.07344L12.5026 8.87234L12.0448 9.07344ZM7.49167 16.6515L7.52884 17.1501L7.49167 16.6515ZM7.45449 16.1529C7.332 16.162 7.20817 16.1667 7.08317 16.1667V17.1667C7.23301 17.1667 7.38164 17.1611 7.52884 17.1501L7.45449 16.1529ZM7.08317 16.1667C4.36777 16.1667 2.1665 13.9654 2.1665 11.25H1.1665C1.1665 14.5177 3.81549 17.1667 7.08317 17.1667V16.1667ZM2.1665 11.25C2.1665 8.5346 4.36777 6.33333 7.08317 6.33333V5.33333C3.81549 5.33333 1.1665 7.98231 1.1665 11.25H2.1665ZM7.08317 6.33333C9.09506 6.33333 10.8258 7.54181 11.587 9.27454L12.5026 8.87234C11.5876 6.78951 9.50597 5.33333 7.08317 5.33333V6.33333ZM13.3332 10.5C14.898 10.5 16.1665 11.7685 16.1665 13.3333H17.1665C17.1665 11.2162 15.4503 9.5 13.3332 9.5V10.5ZM16.1665 13.3333C16.1665 14.8981 14.898 16.1667 13.3332 16.1667V17.1667C15.4503 17.1667 17.1665 15.4504 17.1665 13.3333H16.1665ZM13.3332 16.1667H7.50743V17.1667H13.3332V16.1667ZM7.50743 16.1667C7.77939 16.1667 7.99984 16.3871 7.99984 16.6591H6.99984C6.99984 16.9394 7.22708 17.1667 7.50743 17.1667V16.1667ZM11.587 9.27454C11.8757 9.93169 12.5127 10.5 13.3332 10.5V9.5C13.0071 9.5 12.675 9.26494 12.5026 8.87234L11.587 9.27454ZM7.52884 17.1501C7.24371 17.1714 6.99984 16.9459 6.99984 16.6591H7.99984C7.99984 16.3634 7.74844 16.131 7.45449 16.1529L7.52884 17.1501Z"
      {fill}
    />
    <path
      d="M16.2794 11.2796C16.6988 10.8602 17.0239 10.3561 17.2328 9.80104C17.4417 9.24595 17.5297 8.65266 17.4909 8.06082C17.4521 7.46899 17.2874 6.89225 17.0078 6.36918C16.7283 5.84611 16.3402 5.38876 15.8697 5.0277C15.3991 4.66664 14.8569 4.41019 14.2793 4.27551C13.7017 4.14083 13.102 4.13102 12.5203 4.24673C11.9386 4.36244 11.3883 4.601 10.9062 4.94647C10.4241 5.29194 10.0213 5.73636 9.72473 6.25"
      stroke="currentColor"
    />
  </g>
</svg>
