<!--
//
// Copyright © 2022 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
//
-->
<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M25.0544 4.00001H6.94561C6.52154 3.99992 6.10941 3.99984 5.76949 4.04554C5.38629 4.09706 4.94902 4.22259 4.5858 4.5858C4.22259 4.94902 4.09706 5.38629 4.04554 5.76949C3.99984 6.10941 3.99992 6.52155 4.00001 6.94562V11.0544C3.99992 11.4785 3.99984 11.8906 4.04554 12.2305C4.09706 12.6137 4.22259 13.051 4.5858 13.4142C4.94902 13.7774 5.38629 13.903 5.76949 13.9545C6.1094 14.0002 6.52152 14.0001 6.94558 14H25.0544C25.4785 14.0001 25.8906 14.0002 26.2305 13.9545C26.6137 13.903 27.051 13.7774 27.4142 13.4142C27.7774 13.051 27.903 12.6137 27.9545 12.2305C28.0002 11.8906 28.0001 11.4785 28 11.0544V6.94561C28.0001 6.52154 28.0002 6.10941 27.9545 5.76949C27.903 5.38629 27.7774 4.94902 27.4142 4.5858C27.051 4.22259 26.6137 4.09706 26.2305 4.04554C25.8906 3.99984 25.4785 3.99992 25.0544 4.00001ZM25.9713 6.02871L25.9723 6.03599C25.9979 6.22618 26 6.50034 26 7.00001V11C26 11.4997 25.9979 11.7738 25.9723 11.964L25.9713 11.9713L25.964 11.9723C25.7738 11.9979 25.4997 12 25 12H7.00001C6.50034 12 6.22618 11.9979 6.03599 11.9723L6.02871 11.9713L6.02771 11.964C6.00214 11.7738 6.00001 11.4997 6.00001 11V7.00001C6.00001 6.50034 6.00214 6.22618 6.02771 6.03599L6.02871 6.02871L6.03599 6.02771C6.22618 6.00214 6.50034 6.00001 7.00001 6.00001H25C25.4997 6.00001 25.7738 6.00214 25.964 6.02771L25.9713 6.02871Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.94563 18.0001L14 18.0001L14.0001 25.0545C14.0001 25.4786 14.0002 25.8907 13.9545 26.2306C13.903 26.6138 13.7775 27.0511 13.4143 27.4143C13.051 27.7775 12.6138 27.903 12.2306 27.9545C11.8907 28.0002 11.4786 28.0002 11.0545 28.0001H6.94563C6.52159 28.0002 6.10942 28.0002 5.76952 27.9545C5.38632 27.903 4.94904 27.7775 4.58583 27.4143C4.22261 27.0511 4.09709 26.6138 4.04557 26.2306C3.99987 25.8907 3.99995 25.4786 4.00003 25.0545V20.9457C3.99995 20.5216 3.99987 20.1095 4.04557 19.7696C4.09709 19.3864 4.22261 18.9491 4.58583 18.5859C4.94904 18.2226 5.38632 18.0971 5.76952 18.0456C6.10943 17.9999 6.52157 18 6.94563 18.0001ZM6.02874 20.0288L6.02774 20.0361C6.00217 20.2262 6.00004 20.5004 6.00004 21.0001V25.0001C6.00004 25.4998 6.00217 25.7739 6.02774 25.9641L6.02874 25.9714L6.03602 25.9724C6.22621 25.998 6.50037 26.0001 7.00004 26.0001H11C11.4997 26.0001 11.7739 25.998 11.9641 25.9724L11.9713 25.9714L11.9723 25.9641C11.9979 25.7739 12 25.4998 12 25.0001V20.0001H7.00004C6.50037 20.0001 6.22621 20.0022 6.03602 20.0278L6.02874 20.0288Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M27.4143 18.5859C27.051 18.2226 26.6138 18.0971 26.2306 18.0456C25.8906 17.9999 25.4785 18 25.0545 18.0001L18 18.0001L18 25.0545C18 25.4786 17.9999 25.8907 18.0456 26.2306C18.0971 26.6138 18.2226 27.0511 18.5858 27.4143C18.949 27.7775 19.3863 27.903 19.7695 27.9545C20.1094 28.0002 20.5215 28.0002 20.9456 28.0001H25.0545C25.4785 28.0002 25.8907 28.0002 26.2306 27.9545C26.6138 27.903 27.051 27.7775 27.4143 27.4143C27.7775 27.0511 27.903 26.6138 27.9545 26.2306C28.0002 25.8907 28.0001 25.4786 28 25.0545V20.9457C28.0001 20.5216 28.0002 20.1095 27.9545 19.7696C27.903 19.3864 27.7775 18.9491 27.4143 18.5859ZM25 20.0001C25.4997 20.0001 25.7739 20.0022 25.9641 20.0278L25.9713 20.0288L25.9723 20.0361C25.9979 20.2262 26 20.5004 26 21.0001V25.0001C26 25.4998 25.9979 25.7739 25.9723 25.9641L25.9713 25.9714L25.9641 25.9724C25.7739 25.998 25.4997 26.0001 25 26.0001H21C20.5004 26.0001 20.2262 25.998 20.036 25.9724L20.0287 25.9714L20.0277 25.9641C20.0022 25.7739 20 25.4998 20 25.0001V20.0001H25Z"
  />
</svg>
