<script lang="ts">
  export let size: 'x-small' | 'small' | 'medium' | 'large' | 'full'
  export let fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4 7.99988V23.9999C4 26.209 5.79086 27.9999 8 27.9999H17C17.5523 27.9999 18 27.5522 18 26.9999C18 26.4476 17.5523 25.9999 17 25.9999H8C6.89543 25.9999 6 25.1044 6 23.9999V13.9999H26V17C26 17.5523 26.4477 18 27 18C27.5523 18 28 17.5523 28 17V7.99988C28 5.79074 26.2091 3.99988 24 3.99988H21.9995V2.99988C21.9995 2.44759 21.5518 1.99988 20.9995 1.99988C20.4472 1.99988 19.9995 2.44759 19.9995 2.99988V3.99988H12V2.99988C12 2.44759 11.5523 1.99988 11 1.99988C10.4477 1.99988 10 2.44759 10 2.99988V3.99988H8C5.79086 3.99988 4 5.79074 4 7.99988ZM19.9995 5.99988V6.99988C19.9995 7.55216 20.4472 7.99988 20.9995 7.99988C21.5518 7.99988 21.9995 7.55216 21.9995 6.99988V5.99988H24C25.1046 5.99988 26 6.89531 26 7.99988V11.9999H6V7.99988C6 6.89531 6.89543 5.99988 8 5.99988H10V6.99988C10 7.55216 10.4477 7.99988 11 7.99988C11.5523 7.99988 12 7.55216 12 6.99988V5.99988H19.9995Z"
  />
  <path
    d="M20.2929 21.7071C19.9024 21.3166 19.9024 20.6834 20.2929 20.2929C20.6834 19.9024 21.3166 19.9024 21.7071 20.2929L25 23.5858L28.2929 20.2929C28.6834 19.9024 29.3166 19.9024 29.7071 20.2929C30.0976 20.6834 30.0976 21.3166 29.7071 21.7071L26.4142 25L29.7071 28.2929C30.0976 28.6834 30.0976 29.3166 29.7071 29.7071C29.3166 30.0976 28.6834 30.0976 28.2929 29.7071L25 26.4136L21.6849 29.7287C21.2929 30.0975 20.6761 30.0903 20.2929 29.7071C19.9024 29.3166 19.9024 28.6834 20.2929 28.2929L23.5858 25L20.2929 21.7071Z"
  />
</svg>
