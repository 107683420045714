<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
  <path
    d="M7.1,6.9C7,6.8,6.9,6.7,6.8,6.8C6.6,6.8,6.5,6.9,6.4,7l-2,2.6c-0.2,0.2-0.1,0.5,0.1,0.7c0.1,0.1,0.2,0.1,0.3,0.1 c0.1,0,0.3-0.1,0.4-0.2L6.9,8l1.9,1.5C8.9,9.5,9,9.6,9.2,9.5c0.1,0,0.3-0.1,0.3-0.2l2-2.5c0.2-0.2,0.1-0.5-0.1-0.7 C11.1,6,10.8,6,10.7,6.2L9,8.3L7.1,6.9z"
  />
  <path
    d="M13.3,4.6c1,0,1.8-0.8,1.8-1.8S14.3,1,13.3,1s-1.8,0.8-1.8,1.8S12.3,4.6,13.3,4.6z M13.3,2c0.4,0,0.8,0.4,0.8,0.8 c0,0.4-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8C12.5,2.4,12.9,2,13.3,2z"
  />
  <path
    d="M14.1,5.7c-0.3,0-0.5,0.2-0.5,0.5v4.7c0,1.8-1.1,2.9-2.8,2.9H5.1c-1.7,0-2.8-1.1-2.8-2.9V5.5c0-1.8,1.1-2.9,2.8-2.9h4.8 c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5H5.1c-2.2,0-3.8,1.6-3.8,3.9v5.4c0,2.3,1.5,3.9,3.8,3.9h5.7c2.2,0,3.8-1.6,3.8-3.9V6.2 C14.6,5.9,14.4,5.7,14.1,5.7z"
  />
</svg>
