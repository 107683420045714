<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  export let opacity: string = '1'
</script>

<svg class="svg-{size}" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <g {opacity}>
    <path d="M7.5 17.5H12.5" stroke="currentColor" stroke-linecap="round" />
    <path d="M10 17.5V14.1667" stroke="currentColor" stroke-linecap="round" />
    <path
      d="M4.6665 7.7381C4.6665 7.66499 4.6667 7.64911 4.66729 7.63863C4.68156 7.38455 4.88439 7.18172 5.13847 7.16745C5.14894 7.16687 5.16482 7.16667 5.23793 7.16667H14.7617C14.8349 7.16667 14.8507 7.16687 14.8612 7.16745C15.1153 7.18172 15.3181 7.38455 15.3324 7.63863C15.333 7.64911 15.3332 7.66498 15.3332 7.7381V8.33333C15.3332 11.2789 12.9454 13.6667 9.99984 13.6667C7.05432 13.6667 4.6665 11.2789 4.6665 8.33333V7.7381Z"
      stroke="currentColor"
      stroke-linecap="round"
    />
    <path
      d="M9.51924 9.86264C9.44338 10.1282 9.59712 10.4049 9.86264 10.4808C10.1282 10.5566 10.4049 10.4029 10.4808 10.1374L9.51924 9.86264ZM11.3972 5.10987L10.9164 4.9725L11.3972 5.10987ZM12.5774 3.80235L12.7631 4.26659H12.7631L12.5774 3.80235ZM11.7511 4.24292L11.38 3.90789L11.7511 4.24292ZM10.4808 10.1374L11.8779 5.24723L10.9164 4.9725L9.51924 9.86264L10.4808 10.1374ZM12.7631 4.26659L16.019 2.96424L15.6476 2.03576L12.3918 3.33812L12.7631 4.26659ZM11.8779 5.24723C12.0238 4.73685 12.0628 4.64387 12.1223 4.57796L11.38 3.90789C11.1285 4.18646 11.0352 4.55661 10.9164 4.9725L11.8779 5.24723ZM12.3918 3.33812C11.9902 3.49876 11.6314 3.62933 11.38 3.90789L12.1223 4.57796C12.1818 4.51204 12.2703 4.46373 12.7631 4.26659L12.3918 3.33812Z"
      stroke="currentColor"
    />
  </g>
</svg>
