<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8,7.5c0.5,0,0.8-0.4,0.8-0.8V3.5h6.4v3.3c0,0.5,0.3,0.8,0.8,0.8s0.8-0.4,0.8-0.8v-4C16.8,2.3,16.4,2,16,2H8C7.6,2,7.2,2.3,7.2,2.8v3.9C7.2,7.2,7.6,7.5,8,7.5z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.1,16.5c-0.5,0-0.8,0.4-0.8,0.8v3.2H8.8v-3.2c0-0.5-0.4-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v4c0,0.5,0.4,0.8,0.8,0.8h8.1c0.5,0,0.8-0.4,0.8-0.8v-4C16.9,16.8,16.6,16.5,16.1,16.5z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M21.3,11.3h-5.2l1.2-1.2c0.3-0.3,0.3-0.8,0-1.1s-0.8-0.3-1.1,0l-2.4,2.5c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.1,0.4l2.5,2.5c0.3,0.3,0.8,0.3,1.1,0s0.3-0.8,0-1.1l-1.2-1.2h5.2c0.5,0,0.8-0.3,0.8-0.8S21.7,11.3,21.3,11.3z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.8,15l2.5-2.5c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.1-0.1-0.2-0.2L7.8,9C7.5,8.7,7,8.7,6.7,9s-0.3,0.7,0,1L8,11.2H2.8C2.3,11.2,2,11.6,2,12c0,0.4,0.3,0.8,0.8,0.7h5.1l-1.2,1.2c-0.3,0.3-0.3,0.8,0,1.1S7.5,15.3,7.8,15z"
  />
</svg>
