<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.34943 2.12195L4.41226 2.18673L6.84829 5.19443C6.9204 5.28346 6.95975 5.39455 6.95975 5.50912C6.95975 5.75458 6.78287 5.95873 6.54962 6.00107L6.45975 6.00912L5.2009 6.00836C5.08013 8.94612 4.75119 13.9988 4.01089 13.9988C3.42906 13.9988 3.03779 11.4583 2.83711 6.37732L2.8231 6.01007L1.50001 6.00912C1.38246 6.00912 1.26867 5.96771 1.17862 5.89215C0.990587 5.73437 0.947891 5.46765 1.06539 5.26176L1.11699 5.18773L3.64068 2.18003C3.66126 2.15551 3.68414 2.13302 3.70902 2.11288C3.89976 1.95839 4.16972 1.96734 4.34943 2.12195ZM11.2536 12.0016C11.6675 12.0016 12.003 12.3371 12.003 12.751C12.003 13.1649 11.6675 13.5004 11.2536 13.5004H8.75634C8.34245 13.5004 8.00693 13.1649 8.00693 12.751C8.00693 12.3371 8.34245 12.0016 8.75634 12.0016H11.2536ZM12.2526 9.00393C12.6665 9.00393 13.002 9.33946 13.002 9.75335C13.002 10.1672 12.6665 10.5028 12.2526 10.5028H8.75634C8.34245 10.5028 8.00693 10.1672 8.00693 9.75335C8.00693 9.33946 8.34245 9.00393 8.75634 9.00393H12.2526ZM13.2516 6.00629C13.6655 6.00629 14.001 6.34182 14.001 6.7557C14.001 7.16959 13.6655 7.50511 13.2516 7.50511H8.75634C8.34245 7.50511 8.00693 7.16959 8.00693 6.7557C8.00693 6.34182 8.34245 6.00629 8.75634 6.00629H13.2516ZM14.2506 3.00865C14.6645 3.00865 15 3.34418 15 3.75806C15 4.17195 14.6645 4.50747 14.2506 4.50747H8.75634C8.34245 4.50747 8.00693 4.17195 8.00693 3.75806C8.00693 3.34418 8.34245 3.00865 8.75634 3.00865H14.2506Z"
  />
</svg>
