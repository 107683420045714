<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M21.7,3c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1,0-0.2-0.1-0.3-0.1h-6c-0.4,0-0.8,0.3-0.8,0.8 s0.3,0.8,0.8,0.8h4.2l-8.9,8.9V8.5c0-0.4-0.3-0.8-0.8-0.8S8.8,8.1,8.8,8.5v6c0,0.1,0,0.2,0.1,0.3C8.9,15,9,15.1,9.2,15.2 c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0h6c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-4.2l8.9-8.9V9c0,0.4,0.3,0.8,0.8,0.8 s0.8-0.3,0.8-0.8L21.7,3C21.8,3,21.7,3,21.7,3z"
  />
  <path
    d="M21,12.2c-0.4,0-0.8,0.3-0.8,0.8v7c0,0.1-0.1,0.2-0.2,0.2H4c-0.1,0-0.2-0.1-0.2-0.2V4c0-0.1,0.1-0.2,0.2-0.2h7 c0.4,0,0.8-0.3,0.8-0.8S11.4,2.2,11,2.2H4C3,2.2,2.2,3,2.2,4v16c0,1,0.8,1.8,1.8,1.8h16c1,0,1.8-0.8,1.8-1.8v-7 C21.8,12.6,21.4,12.2,21,12.2z"
  />
</svg>
