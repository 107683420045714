//
// Copyright © 2020 Anticrm Platform Contributors.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
//

import calendar from '@hcengineering/calendar'
import { loadMetadata } from '@hcengineering/platform'

const icons = require('../assets/icons.svg') as string // eslint-disable-line
loadMetadata(calendar.icon, {
  Calendar: `${icons}#calendar`,
  CalendarView: `${icons}#calendarView`,
  Reminder: `${icons}#reminder`,
  Notifications: `${icons}#notifications`,
  Location: `${icons}#location`,
  Watch: `${icons}#watch`,
  Description: `${icons}#description`,
  Participants: `${icons}#participants`,
  Repeat: `${icons}#repeat`,
  Globe: `${icons}#globe`,
  Private: `${icons}#private`,
  Public: `${icons}#public`,
  Hidden: `${icons}#eyeCrossed`
})

const permission = require('../assets/permission.png') as string // eslint-disable-line

loadMetadata(calendar.image, {
  Permissions: permission
})
