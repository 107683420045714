<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.6,7.5c0.5,0,0.8-0.4,0.8-0.8V3.5h17v3.3c0,0.5,0.3,0.8,0.8,0.8S22,7.2,22,6.8v-4C22,2.3,21.7,2,21.3,2H2.8C2.3,2,2,2.3,2,2.8v4C2,7.2,2.3,7.5,2.6,7.5z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M21.4,16.5c-0.5,0-0.8,0.4-0.8,0.8v3.2h-17v-3.2c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.7v4c0,0.5,0.3,0.8,0.8,0.9h18.6c0.5,0,0.8-0.4,0.8-0.8v-4C22.2,16.8,21.9,16.5,21.4,16.5z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M18.2,15.1c0.3,0.3,0.8,0.3,1.1,0l2.5-2.5c0-0.1,0.1-0.2,0.1-0.3S22,12.1,22,12c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2L19.3,9c-0.3-0.3-0.8-0.3-1.1,0s-0.3,0.8,0,1.1l1.2,1.2h-5.2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h5.2L18.2,14C17.9,14.3,17.9,14.8,18.2,15.1z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.3,12.5L4.7,15c0.3,0.3,0.8,0.3,1.1,0s0.3-0.8,0-1.1l-1.2-1.2h5.2c0.4,0,0.8-0.3,0.8-0.8s-0.4-0.7-0.8-0.7H4.6L5.8,10c0.3-0.3,0.3-0.8,0-1.1C5.5,8.6,5,8.7,4.7,9l-2.5,2.5c-0.1,0.1-0.1,0.2-0.2,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3C2.1,12.4,2.2,12.5,2.3,12.5z"
  />
</svg>
